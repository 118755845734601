import React, { useEffect, useState, FC } from 'react';
import { CropperImage } from 'src/components/CropperImage';
import { CustomFile } from '../type';
import { Box } from '@mui/material';

interface IProps {
  file: any;
  onRemove?: (file: File | CustomFile) => void;
  onSetCrop?: (
    file: CustomFile,
    crop: { x: number; y: number; width: number; height: number }
  ) => void;
  aspectRatio: number;
}

const ImageFile: FC<IProps> = ({ file, onSetCrop, onRemove, aspectRatio }) => {
  const [dataCrop, setDataCrop] = useState(
    file.crop ? file.crop : { x: 0, y: 0, width: 0, height: 0 }
  );

  const filePreview = typeof file === 'object' ? file.preview : '';

  useEffect(() => {
    if (onSetCrop && typeof file === 'object') {
      onSetCrop(file, dataCrop);
    }
  }, [dataCrop]);

  return (
    <Box position="relative">
      {onRemove && (
        <button type="button" onClick={() => onRemove(file)}>
          Удалить
        </button>
      )}
      <CropperImage
        onCropGetData={setDataCrop}
        cropImg={filePreview}
        initialCropBoxSize={typeof file === 'object' ? file.crop : undefined}
        aspectRatio={aspectRatio}
      />
    </Box>
  );
};

export default ImageFile;
